<template>
  <div class="mb-3 card transparent">
    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="list">
          {{ $t('settings.lang_fiscalClients') }}
        </v-tab>
        <v-tab v-if="$store.getters['permissions/checkModule'](34)" key="add">{{ $t('settings.lang_addFiscalClient') }}</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <!-- Table -->
            <Datatable v-show="showTable" ref="fiscalClientDatatable"
                       :api-endpoint="dataTableEndpoint"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="Allergene"
                       @editEntry="entryEdit"
                       @deleteEntry="entryDelete"
                       @deleteEntries="entriesDelete"
                       :show-delete-buttons="$store.getters['permissions/checkModule'](34)"
                       :show-edit-buttons="$store.getters['permissions/checkModule'](34)"
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('set_modules')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('set_modules')"
            />

            <!-- Table: Click -->
            <div v-if="showUpdate" style="margin-top: 20px;">
              <FiscalClientsEdit :clientID="fiscalClientID" @showList="showList"></FiscalClientsEdit>
            </div>
          </v-tab-item>

          <v-tab-item v-if="$store.getters['permissions/checkModule'](34)" key="add">
            <FiscalClientsCreate v-if="tab === 1" @showList="showList"></FiscalClientsCreate>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>


<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import Datatable from "../../datatable/Datatable";
import mixin from "../../../mixins/KeyboardMixIns";
import FiscalClientsCreate from "@/components/settings/fiscal_de/FiscalClientsCreate";
import FiscalClientsEdit from "@/components/settings/fiscal_de/FiscalClientsEdit";

export default {
  components: {
    FiscalClientsEdit,
    FiscalClientsCreate,
    Datatable
  },

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      fiscalClientID: 0,
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
    }
  },
  computed: {
    dataTableEndpoint() {
      return this.$store.getters['permissions/checkModule'](74) ? ENDPOINTS.DATATABLES.SETTINGS.FISCAL.FISCALCLIENTSAT : ENDPOINTS.DATATABLES.SETTINGS.FISCAL.FISCALCLIENTS;
    },
    // --- Datatable ---
    datatableHeaders() {
      return [
        {
          text: this.$t('settings.lang_fiscalDeviceID'),
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('settings.lang_fiscalDeviceID'), value: 'devID'},
        {text: this.$t('settings.lang_fiscalClientName'), value: 'clientName'},
        {text: this.$t('settings.lang_fiscalClientBoundCashierID'), value: 'boundCashierID'},
      ];
    },
    excelColumns() {
      return [
        {
          label: this.$t('settings.lang_fiscalDeviceID'),
          field: "id",
        },
        {
          label: this.$t('settings.lang_fiscalClientName'),
          field: "clientName",
        },
        {
          label: this.$t('settings.lang_fiscalClientBoundCashierID'),
          field: "boundCashierID",
        },
      ];
    },
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    createNew: function () {
      this.resetData();

      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    deleteData: function (idsToDelete = []) {
      this.$swal({
        title: this.$t('settings.lang_deleteFiscalClientHead'),
        text: this.$t('settings.lang_deleteFiscalClientBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALCLIENTS.DELETE, {
            fiscalClientID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_actionSuccessful'),
                color: "success"
              });

              this.resetData();

              this.$refs.fiscalClientDatatable.getDataFromApi();
              this.$refs.fiscalClientDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
    },
    showList: function () {
      this.resetData();

      this.$refs.fiscalClientDatatable.getDataFromApi();
      this.$refs.fiscalClientDatatable.resetSelectedRows();

      this.tab = 0;
    },
    entryEdit(entry) {
      this.fiscalClientID = parseInt(entry.id);

      this.showCreate = false;
      this.showUpdate = true;
      this.showTable = false;
    },
    entryDelete(entry) {
      this.id = entry.id;

      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    }
  }
}
</script>