<template>
    <v-dialog persistent width="600" v-model="showDialog">
        <v-card>
            <v-card-title>{{ $t("generic.lang_setUpTseClient") }}</v-card-title>

            <v-card-text class="text-center" v-if="tseLoading">
                <p>
                    {{ $t("generic.lang_tseIsSetUp") }}...
                </p>

                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                <v-progress-linear style="margin-top: 20px;" color="primary" :value="getProgress"></v-progress-linear>
            </v-card-text>

            <v-card-text v-if="!tseLoading && tseErrorMessage.length > 0" class="text-center">
                <b>{{ $t("generic.lang_error") }}</b>
                <br><span v-html="tseErrorMessage"></span>
            </v-card-text>

            <v-card-actions v-if="!tseLoading && tseErrorMessage.length > 0">
                <v-btn text color="error" @click="cancelSetup" small>{{$t("generic.lang_cancel")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="checkDevice">{{$t('generic.lang_retry')}}</v-btn>
            </v-card-actions>
        </v-card>

        <FiscalDeviceSetup v-if="fiscalDevice !== null" ref="fiscalDeviceSetup" :skip-callback-events="!setupFiscalDevice" :fiscal-device-i-d="fiscalDevice.fiscalDeviceID" :fiscal-device-i-p="fiscalDevice.fiscalDeviceIP" :fiscal-device-name="fiscalDevice.fiscalDeviceName" @setupFinish="finishSetupDevice"></FiscalDeviceSetup>
    </v-dialog>
</template>

<script>
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import {mapState} from "vuex";
import FiscalDeviceSetup from "@/components/common/fiscalDevice/FiscalDeviceSetup";

export default {
    name: "FiscalClientSetup",

    components: {
        FiscalDeviceSetup
    },

    props: {
        fiscalDeviceID: Number,
    },

    data() {
        return {
            fiscalDevice: null,
            setupFiscalDevice: false,
            tseLoading: false,
            tseErrorMessage: "",
            tseDevice: null,
            currentStep: 0,

        }
    },

    computed: {
        ...mapState(['api']),
        showDialog() {
            return this.tseLoading || this.tseErrorMessage.length > 0;
        },
        fiscalClientID() {
            return "ClientID"+this.api.auth.posID+"POS";
        },
        getProgress() {
            return ((this.currentStep / 7) * 100);
        }
    },

    mounted() {
        //FIRST GET FISCAL DEVICE
        Events.$on("eposDevice", this.eposDeviceCallback);
        Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback);
    },

    beforeDestroy() {
        Events.$off("eposDevice", this.eposDeviceCallback);
        Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
    },

    methods: {
        closeDialog() {
            this.tseLoading = false;
            this.tseErrorMessage = "";

            //DISCONNECT FROM DEVICE
            if(this.tseDevice !== null)
                this.tseDevice.disconnect(true);
        },
        checkDevice() {
            this.tseLoading = true;
            this.tseErrorMessage = "";

            this.getFiscalDevice();
        },
        getFiscalDevice() {
            this.currentStep = 1;

            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.GET, {
                fiscalDeviceID: this.fiscalDeviceID
            }).then((res) => {
                if (res.status === 200) {
                    this.fiscalDevice = res.data.formfillData.textFields;

                    this.startSetup();
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        startSetup() {
            this.currentStep = 2;

            this.tseLoading = true;

            //ADD NEW TSE PRINTER
            let tseDevices = this.$eposClass.getTSEPrinters();

          let id = "CLIENT_SETUP_"+this.fiscalDevice.fiscalDeviceIP+"_"+this.fiscalDevice.fiscalDeviceID;
console.log("Start Setup");
            //CHECK IF TSE PRINTER EXISTS
            if(!tseDevices.hasOwnProperty("CLIENT_SETUP"))
                this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
                    id: id, //ID === NAME
                    ip: this.fiscalDevice.fiscalDeviceIP,
                    TSEProxyIPAdress: this.fiscalDevice.TSEProxyIPAdress,
                    port: this.fiscalDevice.fiscalDevicePort,
                    deviceID: this.fiscalDevice.fiscalDeviceID,
                    adminID: 'Administrator',
                    clientID: null
                }, false, false, (this.fiscalDevice.useTSEProxy === 1));

            this.tseDevice = this.$eposClass.getTSEPrinter(id);
            console.log(this.tseDevice);
          console.log("Finish Setup");
        },

        cancelSetup() {
            this.closeDialog();

            this.$emit("cancelSetup");
        },
        setupFinish() {
            this.closeDialog();

            this.$emit("setupFinish");
        },
        finishSetupDevice() {
            this.setupFiscalDevice = false;

            Events.$on("eposDevice", this.eposDeviceCallback);
            Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback);

            //RE-CALL SETUP FUNCTION
            this.startSetup();
        },

        // --- CALLBACKS ---
        eposDeviceCallback(payload) {
            if (payload.action === "createDeviceCallback.success") {
                this.currentStep = 3;
            }

            if (payload.action === "createDeviceCallback.error") {
                this.tseLoading = false;
                this.tseErrorMessage = `Einrichtung der TSE konnte nicht gestartet werden.<br>(${payload.code})`;
            }

            if (payload.action === "connectCallback.error") {
                //CONNECTION ERROR
                this.tseLoading = false;
                this.tseErrorMessage = "Verbindung mit der TSE konnte nicht aufgebaut werden. Bitte überprüfe die IP-Adresse und stelle sicher, dass das Gerät eingeschaltet ist."
            }

            if (payload.action === "disconnect") {
                this.tseDevice = null;
            }
        },
        GFE_OnReceiveCallback(payload) {
            //AUTH USER FOR ADMIN
            if(payload.result.function === "AuthenticateUserForAdmin") {
                if (payload.result.result === "EXECUTION_OK") {
                    this.currentStep = 5;

                    //REGISTER SECRET KEY
                    this.tseDevice.GFE_RegisterSecretKey("Administrator");
                }
                else {
                    //CHECK IF WE NEED TO SETUP TSE FIRST
                    if(payload.result.result === "TSE1_ERROR_WRONG_STATE_NEEDS_PUK_CHANGE") {
                        //SETUP TSE!!!
                        //REMOVE EVENT LISTENERS
                        Events.$off("eposDevice", this.eposDeviceCallback);
                        Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback);

                        this.tseDevice.ePosDev.disconnect();

                        this.setupFiscalDevice = true;

                        this.$refs.fiscalDeviceSetup.checkDevice();
                    }
                    else {
                        //AUTH USER ADMIN ERROR
                        this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
                        this.tseLoading = false;
                    }

                }
            }

            //REGISTER SECRET KEY
            if(payload.result.function === "RegisterSecretKey") {
                if (payload.result.result === "EXECUTION_OK") {
                    this.currentStep = 6;

                    //REGISTER CLIENT
                    this.tseDevice.GFE_RegisterClient(this.fiscalClientID);
                }
                else {
                    //REGISTER SECRET KEY ERROR
                    this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
                    this.tseLoading = false;
                }
            }

            //REGISTER CLIENT
            if(payload.result.function === "RegisterClient") {
                if (payload.result.result === "EXECUTION_OK") {
                    this.currentStep = 7;

                    //CALL FINISH
                    this.setupFinish();
                }
                else {
                    //REGISTER CLIENT ERROR
                    this.tseErrorMessage = `Es ist ein Fehler aufgetreten<br>(${payload.result.result})`;
                    this.tseLoading = false;
                }
            }
        }
    }
}
</script>