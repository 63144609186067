<template>
    <v-container fluid>
        <v-row v-if="loading">
            <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>

        <div v-else>
            <v-layout>
                <v-flex md6 mr-2 ml-2>
                    <v-text-field outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="fiscalClientName"
                                  :label="$t('settings.lang_fiscalClientName')"
                                  autocomplete="off"
                                  required
                    ></v-text-field>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                    <v-select outlined
                              :items="fiscalDevices"
                              item-text="name"
                              item-value="id"
                              v-model="fiscalDeviceID"
                              :label="$t('settings.lang_fiscalDeviceName')"
                    ></v-select>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex md6 mr-2 ml-2>
                    <v-select outlined
                              :items="filteredCashierIDs"
                              multiple
                              item-text="name"
                              item-value="id"
                              v-model="fiscalClientBoundCashierID"
                              :label="$t('generic.lang_forCashierID')"
                    ></v-select>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex md12 class="text-right">
                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                    <v-btn color="success" :disabled="fiscalClientName.length < 1" @click="addData">{{
                            $t('generic.lang_add') }}
                    </v-btn>
                </v-flex>
            </v-layout>

            <FiscalClientSetup ref="fiscalClientSetup" :fiscalDeviceID="fiscalDeviceID" :cashierID="fiscalClientBoundCashierID" @setupFinish="setupFinish"></FiscalClientSetup>

            <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                    :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                    :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                    :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
            </div>
        </div>
    </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config'
import mixin from "../../../mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import FiscalClientSetup from "@/components/common/fiscalClient/FiscalClientSetup";

export default {
    name: "FiscalClientsCreate",

    components: {
        FiscalClientSetup
    },

    mixins: [mixin],

    data() {
        return {
            ENDPOINTS,
            fiscalDevices: [],
            fiscalClients: [],
            cashierIDs: [],
            fiscalClientName: "",
            fiscalClientBoundCashierID: 0,
            fiscalDeviceID: 0,
            loading: false,
        }
    },

    mounted() {
        this.getCashierIDs();
    },

    computed: {
        filteredCashierIDs() {
            let cashierIDs = [];

            this.cashierIDs.forEach((cashierID) => {
                //CHECK IF CASHIERID WAS ALREADY ASSIGNED
                let cashier = this.fiscalClients.find((fiscalClient) => fiscalClient.fiscalClientBoundCashierID === parseInt(cashierID));

                if(!cashier) {
                    cashierIDs.push({
                        name: this.$t('generic.lang_cashierID') + " " + parseInt(cashierID),
                        id: parseInt(cashierID)
                    });
                }
            });

            return cashierIDs;
        }
    },

    methods: {
        getCashierIDs() {
            this.loading = true;

            this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET, {}).then((res) => {
                if (res.status === 200) {
                    this.cashierIDs = res.data.cashierIDs;

                    this.getAllFiscalClients();
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        getAllFiscalClients() {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALCLIENTS.GET).then((res) => {
                if(res.data.success) {
                    this.fiscalClients = res.data.fiscalClients;

                    this.getAvailableFiscalDevices();
                }
                else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        getAvailableFiscalDevices() {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICES.GET, {}).then((res) => {
                if (res.status === 200) {
                    this.fiscalDevices = res.data.fiscalDevices.map((device) => {
                        return {
                            name: device.name,
                            id: parseInt(device.id)
                        };
                    });

                    this.loading = false;
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        addData () {
            this.$refs.fiscalClientSetup.checkDevice();
        },
        setupFinish() {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALCLIENTS.CREATE, {
                fiscalClientName: this.fiscalClientName,
                fiscalClientBoundCashierID: this.fiscalClientBoundCashierID,
                fiscalDeviceID: this.fiscalDeviceID
            }).then((res) => {
                if (res.data.success) {
                    Events.$emit("showSnackbar", {
                        message: this.$t('settings.lang_fiscalClientAdded'),
                        color: "success"
                    });

                    this.showList();
                } else {
                    if(res.data.code === "already_exists") {
                        Events.$emit("showSnackbar", {
                            message: this.$t('settings.lang_fiscalClientAdded'),
                            color: "success"
                        });

                        this.showList();
                    }
                    else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        showList() {
            this.$emit("showList");
        }
    }
}
</script>