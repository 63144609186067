<template>
    <v-container>
        <div v-if="!loading">
            <v-layout>
                <v-flex md6 mr-2 ml-2>
                    <v-text-field outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="fiscalClientName"
                                  :label="$t('settings.lang_fiscalClientName')"
                                  autocomplete="off"
                                  required
                    ></v-text-field>
                </v-flex>

                <v-flex md6 mr-2 ml-2>
                    <v-select outlined
                              :items="fiscalDevices"
                              item-text="name"
                              item-value="id"
                              v-model="fiscalDeviceID"
                              :label="$t('settings.lang_fiscalDeviceName')"
                    ></v-select>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex md6 mr-2 ml-2>
                    <v-select outlined
                              :items="cashierIDs"
                              item-text="name"
                              item-value="id"
                              multiple
                              v-model="fiscalClientBoundCashierID"
                              :label="$t('generic.lang_forCashierID')"

                    ></v-select>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex md12 class="text-right">
                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev')
                        }}
                    </v-btn>

                    <v-btn color="success" :disabled="fiscalClientName.length < 1" @click="saveData">
                        {{ $t('generic.lang_edit') }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config'
import mixin from "../../../mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";

export default {
    name: "FiscalClientsEdit",
    mixins: [mixin],

    props: {
      clientID: Number
    },

    data() {
        return {
            ENDPOINTS,
            loading: true,
            fiscalDevices: [],
            cashierIDs: [],
            fiscalClientName: "",
            fiscalClientBoundCashierID: [],
            fiscalDeviceID: 0,
        }
    },

    mounted() {
        this.getCashierIDs();
    },

    methods: {
        getCashierIDs() {
            this.loading = true;

            this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET, {}).then((res) => {
                if (res.status === 200) {
                    this.cashierIDs = res.data.cashierIDs.map((cashierID) => {
                        return {
                            name: this.$t('generic.lang_cashierID') + " " + parseInt(cashierID),
                            id: parseInt(cashierID)
                        };
                    });

                    this.getAvailableFiscalDevices();
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        getAvailableFiscalDevices() {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICES.GET, {}).then((res) => {
                if (res.status === 200) {
                    this.fiscalDevices = res.data.fiscalDevices.map((device) => {
                        return {
                            name: device.name,
                            id: parseInt(device.id)
                        };
                    });

                    this.getFiscalClient();
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        getFiscalClient() {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALCLIENT.GET, {
                fiscalClientID: this.clientID
            }).then((res) => {
                if (res.status === 200) {
                    this.fiscalClientName = res.data.formfillData.textFields.fiscalClientName;
                    this.fiscalClientBoundCashierID = res.data.formfillData.textFields.fiscalClientBoundCashierID;
                    this.fiscalDeviceID = parseInt(res.data.formfillData.textFields.fiscalDeviceID);

                    this.loading = false;
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });

        },
        saveData() {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALCLIENTS.UPDATE, {
                fiscalClientID: this.clientID,
                fiscalClientName: this.fiscalClientName,
                fiscalClientBoundCashierID: this.fiscalClientBoundCashierID,
                fiscalDeviceID: this.fiscalDeviceID
            }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_actionSuccessful'),
                        color: "success"
                    });

                    this.showList();
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        showList() {
            this.fiscalClientBoundCashierID = [];
            this.fiscalDeviceID = 0;
            this.fiscalClientName = "";

            this.$emit("showList");
        }
    }
}
</script>